$(function () {
    if ($('#map').length) {
        $.ajax({
            dataType: "json",
            url: trans_url("map/get-markers"),
            success: function(data) {
                var options = {};
                options.sidebar_centers_count = false;
                init_map(data, options);
            }
        });
    }
});

var map; // global map
var infos = [];
var markers = []; // global markers
var center_count = false;

function get_closest_markers(pos, count) {
    if (!count) count = center_count;
    if (!markers.length) return [];
    var m = markers.slice(); // copy array
    m.sort(compare_dist(pos));

    if (count) {
        return m.slice(0, count);
    }
    return m;
}

function compare_dist(marker) {
    return function(a, b) {
        var dista = google.maps.geometry.spherical.computeDistanceBetween(a.position, marker);
        var distb = google.maps.geometry.spherical.computeDistanceBetween(b.position, marker);
        var dist = dista - distb;
        return dist;
    }
}

function closeInfos() {
    if (infos.length > 0) {
        infos[0].set("marker", null);
        infos[0].close();
        infos.length = 0;
    }
}


function draw_centers(nodes) {
    var $locations = $('#location-list');
    var $tpl = $('#location-template li');

    if (nodes.length) {
        $locations.html('');
        for (i in nodes) {
            var $center = $tpl.clone();
            var marker = nodes[i].data;

            if(marker.telephone !== null) {
                var telephoneLink = marker.telephone.replace('(', '').replace(')', '').replace(' ', '');
                $('.data-telephone', $center).html(marker.telephone).attr('href', 'tel:' + telephoneLink);
            }

            $('.data-name', $center).html(marker.name);
            $('.data-address', $center).html(marker.address);
            $('.data-city', $center).html(marker.city + ', ' + marker.province);

            $center.data('id', marker.id);
            $center.data('province', parseInt(marker.province_id));
            $center.data('city', parseInt(marker.city_id));
            $center.data('telephone', marker.telephone);

            $locations.append($center);
        }
    } else {
        $locations.html('<li class="not-found">No se encontraron centros</li>')
    }

    $('.marker-contact').click(function () {
        var id = $(this).closest('li').data('id');
        window.location = trans_url("contact-us") + id;
        return false;
    });

    $('.map-pin', $locations).click(function() {
        var $this = $(this);
        var id = $this.closest('li').data('id');
        if (!id)
            return false;
        marker = false;
        for (i in markers) {
            if (markers[i].id == id) {
                marker = markers[i];
                break;
            }
        }

        if (!marker)
            return false;

        closeInfos();
        var newbounds = new google.maps.LatLngBounds();
        newbounds.extend(marker.position);
        map.fitBounds(newbounds);
        map.panTo(marker.position);
        map.setZoom(15);
        google.maps.event.trigger(marker, 'click');

        return false;
    });
}

function load_map_ui(data) {
    var $provinces = $('#filter-provinces');
    var $cities = $('#filter-cities');

    var cities_idle = $('#filter-cities').get(0).outerHTML;

    $provinces.change(function() {
        var $this = $(this);
        var cities = $this.find('option:selected').data('cities');
        
        $cities.html(cities_idle);
        if (!cities) {
            $cities.attr('disabled', 'disabled');
        } else {
            for (i in cities) {
                var city = cities[i];
                var o = $('<option />', {
                    'value': city.id,
                    'html': city.name
                });
                $cities.append(o);
            }
            $cities.removeAttr('disabled');
        }

        filter_centers();
    }).removeAttr('disabled');

    $cities.change(function() {
        filter_centers();
    });

    function filter_centers() {
        var province = $provinces.val();
        var city = $cities.val();

        var nodes = [];
        
        if (province) {
            for (i in markers) {
                var marker = markers[i];
                var show = true;
                if (parseInt(province) != parseInt(marker.data.province_id)) {
                    show = false;
                }
                if (show && city) {
                    show = (parseInt(city) == parseInt(marker.data.city_id));
                }
                if (show) {
                    nodes.push(marker);
                }
            }
        } else {
            nodes = markers;
        }

        draw_centers(nodes);
        fit_nodes(nodes);
    }

    function fit_nodes(nodes) {
        var markerBounds = new google.maps.LatLngBounds();

        for (i in nodes) {
            var marker = nodes[i];
            markerBounds.extend(marker.position);
        }
        map.fitBounds(markerBounds);
    }

    function center_map(pos) {
        if (!pos) return false;
        
        if (!this.marker_here) {
            this.marker_here = new google.maps.Marker({
                position: pos,
                map: map,
                icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|3491FF|3491FF",
                title: 'Usted esta aquí'
            });
        } else {
            this.marker_here.setPosition(pos);
        }

        closeInfos();
        var infowindow = new google.maps.InfoWindow({
            content: 'Usted esta aquí'
        });

        infowindow.open(map, this.marker_here);
        infos[0] = infowindow;


        /* var m = get_closest_markers(pos, 5);
        console.log(m);
        draw_centers(m);
        fit_nodes(m.concat([{
            position: pos
        }])); */
        map.panTo(pos);

        return true;
    }

    $('.btn-geolocation').click(function() {
        $provinces.val('').change();
        // Try HTML5 geolocation.
        var error = false;
        fit_nodes(markers);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                center_map(pos);
            });
        } else {
            // Browser doesn't support Geolocation
            handleLocationError(false);
        }
        return false;
    });

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        if (!browserHasGeolocation) {
            alert('Error: Su navegador no soporta geolocalización.');
        }
    }
}

function init_map(data, params) {
    if (typeof(params.sidebar_centers_count) !== 'undefined') {
        center_count = params.sidebar_centers_count;
    }

    load_map_ui(data);

    var center = new google.maps.LatLng(-13.5415881, -69.7814282);

    var options = {
        zoom: 4,
        center: center,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        //icon: image,
        styles: [{
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [{
                "visibility": "on"
            }, {
                "lightness": 33
            }]
        }, {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{
                "color": "#f2e5d4"
            }]
        }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{
                "color": "#c5dac6"
            }]
        }, {
            "featureType": "poi.park",
            "elementType": "labels",
            "stylers": [{
                "visibility": "on"
            }, {
                "lightness": 20
            }]
        }, {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{
                "lightness": 20
            }]
        }, {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [{
                "color": "#c5c6c6"
            }]
        }, {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [{
                "color": "#e4d7c6"
            }]
        }, {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [{
                "color": "#fbfaf7"
            }]
        }, {
            "featureType": "water",
            "elementType": "all",
            "stylers": [{
                "visibility": "on"
            }, {
                "color": "#acbcc9"
            }]
        }]
    };

    map = new google.maps.Map(document.getElementById('map'), options);
    var markerBounds = new google.maps.LatLngBounds();

    /*
    var default_icons = {
        'store': site_url + '/assets/img/map/marker-vet.png',
    }
    */

    markerLenght = data.markers.length;
    for (i in data.markers) {
        var marker = data.markers[i];
        //var marker_img = default_icons[marker.type];
        //var image = new google.maps.MarkerImage(marker_img, new google.maps.Size(46, 48), new google.maps.Point(0, 0), new google.maps.Point(35, 48));
        var mdata = new google.maps.Marker({
            id: marker.id,
            position: new google.maps.LatLng(marker.map_lat, marker.map_lng),
            title: marker.title,
            data: marker,
            //icon: image,
            map: map,
            animation: google.maps.Animation.DROP
        });

        markerBounds.extend(mdata.position);
        markers.push(mdata);
        var infowindow = new google.maps.InfoWindow({
            maxWidth: 310
        });

        if(marker.telephone !== null) {
            var telephoneLink = marker.telephone.replace('(', '').replace(')', '').replace(' ', '');
            var content = '<div style="max-width:300px;width:100%"><h4 style="display:inline-block">' + marker.name + '</h4><p>' + marker.address + '</p><small>' + marker.city + ',  ' + marker.province + '</small><div><a href="tel:' + telephoneLink + '">' + marker.telephone + '</a></div><div style="text-align:left;margin-top:15px;"></div></div>';
        }
        else {
            var content = '<div style="max-width:300px;width:100%"><h4 style="display:inline-block">' + marker.name + '</h4><p>' + marker.address + '</p><small>' + marker.city + ',  ' + marker.province + '</small><div style="text-align:left;margin-top:15px;"></div></div>';
        }

        google.maps.event.addListener(mdata, 'click', (function(mdata, content, infowindow) {
            return function() {
                closeInfos();
                infowindow.setContent(content);
                infowindow.setPosition(mdata.getPosition());
                infowindow.open(map, mdata);
                infos[0] = infowindow;
            };
        })(mdata, content, infowindow));
    }

    var options = {
        gridSize: 40,
        maxZoom: 16,
        minimumClusterSize: 2,
        imagePath: dist_url('frontend/images/map/cluster')
    };

    var markerCluster = new MarkerClusterer(map, markers, options);

    /* map.addListener('idle', function() {
        if (this.timer) {
            window.clearInterval(this.timer);
        }

        timer = window.setTimeout(function() {
            draw_centers(get_closest_markers(map.getCenter()));
        }, 1000);
    }); */

    draw_centers(get_closest_markers(center));

    $('#filter-provinces').change();

    $('.btn-geolocation').click();
}
