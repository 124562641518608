(function ($) {
    $(function () {
        if (!$('#cart-display').length) {
            return false;
        }

        $.Shop = function (element) {
            this.init();
        };

        $.Shop.prototype = {
            init: function () {
                // Properties

                this.cartPrefix = "elite-"; // prefix string to be prepended to the cart's name in session storage
                this.cartName = this.cartPrefix + "cart"; // cart's name in session storage
                this.storage = sessionStorage; // shortcut to sessionStorage object

                this.$cartDisplay = $('#cart-display'); // Shopping cart form
                this.$cartElements = $('.item-list tbody', this.$cartDisplay);

                this.$cartCount = $('.cart-item-count');
                this.cartElementTpl = this.$cartElements.html().trim();

                this.$shopGrid = $('#shop-grid');

                if (this.$shopGrid.length) {
                    this.$shopGridElements = $('.shop-grid-list tbody', this.$shopGrid)
                    this.$shopGridEmpty = $('#shop-grid-empty');
                    this.shopListTpl = this.$shopGridElements.html().trim();
                }

                this.buttonAdd = '.btn-buy';
                this.inputQty = '.input-qty';
                this.buttonRemove = '.btn-remove';
                this.shopMenuIconWrapper = '.navbar li.cotizador';

                this.currency = "&dollar;"; // HTML entity of the currency to be displayed in layout
                this.currencyString = "$"; // currency symbol as text string
                this.strElementsSingular = trans('shop_product');
                this.strElementsPlural = trans('shop_products');
                this.strTooltipAddedSingular = trans('shop_product added');
                this.strTooltipAddedPlural = trans('shop_products added');
                this.navShopElementCount = 3;
                this.fieldsToForm = ['code', 'title', 'qty'];

                // methods invocation
                this.createCart();
                this.handleButtonsAdd();
                this.handleButtonsCount();
                this.handleButtonsRemove();

                return this;
            },

            createCart: function () {
                var self = this;

                if (this.storage.getItem(this.cartName) == null) {
                    var cart = {};
                    cart.items = [];
                    this.storage.setItem(this.cartName, JSON.stringify(cart));
                }

                self._updateCart();
                return this;
            },

            emptyCart: function () {
                this.storage.clear();
            },

            handleButtonsAdd: function () {
                var self = this;

                $('body').on('click', self.buttonAdd, function (e) {
                    e.preventDefault();

                    var $this = $(this);
                    var $wrapper = $this.closest('.shop-add');

                    if (!$wrapper.length || !$wrapper.data('code') || !$wrapper.data('title')) {
                        console.log('Shop error: wrong add button markup.');
                        return false;
                    }

                    var qty = 1;
                    $qty = $wrapper.find('input[name=qty]');
                    if ($qty.length) {
                        qty = parseInt($qty.val());
                    }

                    if (qty < 1) return false;

                    if (qty == 1) {
                        self.showTooltip(this, qty + ' ' + self.strTooltipAddedSingular);
                    } else {
                        self.showTooltip(this, qty + ' ' + self.strTooltipAddedPlural);
                    }

                    self.addItem(qty, $wrapper.data('code'), $wrapper.data('title'), $wrapper.data('description'), $wrapper.data('pic'));
                    self._updateCart();
                });

                return this;
            },

            handleButtonsCount: function () {
                var self = this;

                $('body').on('change', self.inputQty, function (e) {

                    var $this = $(this);
                    var $wrapper = $this.closest('.shop-qty');

                    if (!$wrapper.length || !$wrapper.data('code')) {
                        console.log('Shop error: wrong count input markup.');
                        return false;
                    }

                    var code = $wrapper.data('code');

                    var qty = parseInt($this.val());

                    if (isNaN(qty)) return false;

                    if (qty > 0) {
                        item = self._getItem(code);

                        if (!code) return false;
                        item.qty = qty;
                        self._setItem(item);
                    } else {
                        self._removeItem(code);
                    }

                    self._updateCart();
                });

                return this;
            },

            handleButtonsRemove: function () {
                var self = this;

                $('body').on('click', self.buttonRemove, function (e) {
                    e.preventDefault();

                    var $this = $(this);
                    var $wrapper = $this.closest('tr');

                    if (!$wrapper.length || !$wrapper.data('code')) {
                        console.log('Shop error: wrong remove button markup.');
                        return false;
                    }

                    var code = $wrapper.data('code');
                    self._removeItem(code);
                    self._updateCart();
                });

                return this;
            },

            showTooltip: function (elem, message) {
                var options = {content: message, placement: 'top'};
                $(elem).popover(options);
                $(elem).popover('show');
                $(elem).prop('disabled', true);
                setTimeout(function () {
                    $(elem).prop('disabled', false);
                    $(elem).popover('dispose');
                }, 2000);

            },

            addItem: function (qty, code, title, description, pic) {
                var item = this._getItem(code);
                if (item) {
                    item.qty += qty;
                } else {
                    item = {
                        code: code,
                        title: title,
                        description: description,
                        pic: pic,
                        qty: qty
                    }
                }

                this._setItem(item);
                return this;
            },

            getFormItems: function () {
                var cart_json = this.storage.getItem(this.cartName);
                var cart = JSON.parse(cart_json);

                export_fields = this.fieldsToForm;
                var output = [];
                $.each(cart.items, function (key, item) {
                    output.push({
                        code: item.code,
                        title: item.title,
                        qty: item.qty
                    });
                });

                return output;
            },

            // Provate methods
            _updateCart: function () {
                var self = this;

                var $cart = self.$cartDisplay;
                var $count = self.$cartCount;
                var cart = self._getCart();

                var items = 0;
                $.each(cart.items, function (key, item) {
                    items += item.qty;
                });

                $count.html(items + ' ' + (items == 1 ? self.strElementsSingular : self.strElementsPlural) + '.');

                var $list = this.$cartElements;
                $list.html('');
                var row_tpl = this.cartElementTpl;

                if (cart.items.length < 1) {
                    $cart.find('.cart-description').hide();
                    $(self.shopMenuIconWrapper).removeClass('full');
                } else {
                    $.each(cart.items.reverse(), function (key, elem) {
                        var row_html = row_tpl;
                        $.each(elem, function (attr, value) {
                            regex = new RegExp('\{' + attr + '\}', 'g');
                            row_html = row_html.replace(regex, value);
                        });
                        var $item = $.parseHTML(row_html);
                        $('input[name=qty]', $item).val(elem.qty);
                        $list.append($item);
                        return key < self.navShopElementCount - 1;
                    });
                    $(self.shopMenuIconWrapper).addClass('full');
                    $cart.find('.cart-description').show();
                }

                // Shop grid

                if (self.$shopGrid.length) {

                    row_tpl = self.shopListTpl;
                    $list = self.$shopGridElements;
                    $list.html('');

                    if (cart.items.length < 1) {
                        self.$shopGrid.hide();
                        self.$shopGridEmpty.show();
                    } else {
                        $.each(cart.items, function (key, elem) {
                            var row_html = row_tpl;
                            $.each(elem, function (attr, value) {
                                regex = new RegExp('\{' + attr + '\}', 'g');
                                row_html = row_html.replace(regex, value);
                            });
                            var $item = $.parseHTML(row_html);
                            $('input[name=qty]', $item).val(elem.qty);
                            $('.pic', $item).attr('src', elem.pic);
                            $list.append($item);
                        });
                        self.$shopGrid.show();
                        self.$shopGridEmpty.hide();
                    }

                    // Mobile version of grid table
                    $('.cart-mobile-table', self.$shopGrid).remove();
                    var $table = $list.closest('table');
                    var $fixedColumn = $table.clone().insertBefore($table).addClass('fixed-column cart-mobile-table');
                    $fixedColumn.find('th:not(:first-child),td:not(:first-child)').remove();
                    $fixedColumn.find('tr').each(function (i, elem) {
                        $(this).height($table.find('tr:eq(' + i + ')').height());
                    });
                }
            },

            _getCart: function () {
                var cart_json = this.storage.getItem(this.cartName);
                return JSON.parse(cart_json);
            },

            _setCart: function (cart) {
                this.storage.setItem(this.cartName, JSON.stringify(cart));
                return this;
            },

            _getItem: function (code) {
                var cart = this._getCart();
                var found = false;
                $.each(cart.items, function (key, elem) {
                    if (elem.code == code) {
                        found = elem;
                        return false;
                    }
                });
                return found;
            },

            _setItem: function (item) {
                var cart = this._getCart();

                $.each(cart.items, function (key, elem) {
                    if (elem.code == item.code) {
                        cart.items.splice(key, 1);
                        return false;
                    }
                });

                cart.items.push(item);

                this._setCart(cart);
                return this;
            },

            _removeItem: function (code) {
                var self = this;
                var cart = this._getCart();

                items = $.grep(cart.items, function (item) {
                    return item.code !== code;

                });

                cart.items = items;
                this._setCart(cart);
                return this;
            },

            _getItemCount: function () {
                var self = this;
                var cart = this._getCart();
                var count = 0;
                $.each(cart.items, function () {
                    count += this.qty;
                });
                return qty;
            }

        };


        // Form binding

        function bindShopForm(shop) {

            $("#shop-form").validate({
                ignore: [],
                focusInvalid: false,
                invalidHandler: function (form, validator) {
                    if (!validator.numberOfInvalids())
                        return;
                    var elem = validator.errorList[0].element.id;
                    var $elem = $("#" + elem);
                    var pos = $elem.offset().top - 300;
                    $(window).scrollTop(pos);
                    $elem.focus();
                },
                onfocusout: false,
                rules: {
                    user_firstname: {
                        required: true,
                        minlength: 2
                    },
                    user_lastname: {
                        required: true,
                        minlength: 2
                    },
                    user_email: {
                        required: true,
                        email: true
                    },
                    user_area_code: {
                        required: true,
                        minlength: 2
                    },
                    user_telephone: {
                        required: true,
                        minlength: 5
                    },
                    company_name: {
                        required: true,
                        minlength: 2
                    },
                    company_address: {
                        required: true,
                        minlength: 2
                    },
                    // company_rut: {
                    //     required: true,
                    //     minlength: 2
                    // },
                    region: {
                        required: true
                    },
                    province: {
                        required: true
                    },
                    city: {
                        required: true
                    },
                    company_area: {
                        required: true
                    },
                    employee_count: {
                        required: true
                    },
                    sales_rep_id: {
                        required: true
                    },
                    'g-recaptcha-response': {
                        recaptcha: true
                    }
                },
                messages: {},
                showErrors: function (errorMap, errorList) {
                    blnValidated_at_least_once = true;
                    $.each(this.successList, function (index, value) {

                        var _popover = $(value).popover({
                            trigger: 'manual',
                            placement: 'top',
                            content: value.message,
                            container: '.form-group',
                            template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                        });

                        $(value).popover('hide');
                    });
                    var x_count = 0;
                    $.each(errorList, function (index, value) {

                        if (x_count > 0) {

                        } else {
                            x_count++;

                            if (value.element.id.indexOf("cha_txt") > -1) {

                                var _popover = $(value.element).popover({
                                    trigger: 'manual',
                                    placement: 'bottom',
                                    content: '',
                                    container: 'body',
                                    template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                                });

                                $(value.element).attr('data-content', value.message);

                                _popover.popover('show');
                                setTimeout(function () {
                                    $(value.element).popover('hide');
                                }, 2000);

                            } else {

                                var _popover = $(value.element).popover({
                                    trigger: 'manual',
                                    placement: 'top',
                                    content: '',
                                    container: 'body',
                                    template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                                });

                                $(value.element).attr('data-content', value.message);

                                _popover.popover('show');

                                setTimeout(function () {
                                    $(value.element).popover('hide');
                                }, 2000);

                            }
                        }
                    });
                },
                submitHandler: function (form) {
                    if (!window.already_submitted) {
                        window.already_submitted = true;
                        var detail = $('<input />').attr({
                            type: 'hidden',
                            name: 'detail',
                            value: JSON.stringify(shop.getFormItems())
                        }).appendTo(form);
                        shop.emptyCart();
                        form.method = "POST";
                        form.action = trans_url("get-a-quote") + "proc";
                        form.submit();
                    }
                }
            });


            $.validator.addMethod("recaptcha", function (value, element, param) {
                return (grecaptcha.getResponse() != '');
            }, trans('shop_must_validate_recaptcha'));


        }

        $(function () {
            var shop = new $.Shop(); // object's instance
            bindShopForm(shop);
        });
    });
})(jQuery);