var base_url = function (args) {
    return "/" + args;
};
var dist_front_url = function (args) {
    return "/assets/" + args;
};

var asset_url = function (args) {
    return "/assets/" + args;
};
var dist_url = function (args) {
    return "/assets/dist/" + args;
};
var image_url = function (args) {
    return "/assets/images/" + args;
};
var upload_url = function (args) {
    return "/assets/uploads/" + args;
};

var trans_url = function (args) {
    if (!args) {
        return base_url('');
    }
    if (typeof(trans_urls[args]) !== 'undefined') {
        return base_url(trans_urls[args]) + "/";
    } else {
        return base_url(args) + "/";
    }

};
var trans = function (args) {
    if (!args) {
        return '';
    }
    if (typeof(trans_strings[args]) !== 'undefined') {
        return trans_strings[args];
    } else {
        return args;
    }

};

function _(term) {
    var ll = window.ll_lang;
    if (typeof(ll) !== 'undefined' || typeof(ll[term]) !== 'undefined') return ll[term];
    return term;
}

// Select cascade
jQuery.fn.extend({
    cascade: function () {
        this.each(function () {
            var $this = $(this);
            if (!$this.is('select')) return;

            var parent_select = $this.data('parent');
            if (!parent_select) return true;
            var $parent = $('#' + parent_select);

            if ($parent.length < 1) {
                console.log('Select cascade: Unable to find parent select "#' + parent_select + '".');
                return true;
            }

            var path = $this.data('url');
            var preloaded = $this.data('preloaded');

            var empty_select = $this.html().trim();

            $parent.change(function () {

                if (!$parent.val()) {
                    $this.html(empty_select).attr('disabled', 'disabled');
                    if ($this.is('.mdb-select')) {
                        $this.material_select('destroy');
                        $this.material_select();
                    }
                    return;
                }

                var url = path + $parent.val();

                $parent.attr('disabled', 'disabled');
                if ($parent.is('.mdb-select')) {
                    $parent.material_select('destroy');
                    $parent.material_select();
                }

                $this.html(empty_select).attr('disabled', 'disabled').change();
                if ($this.is('.mdb-select')) {
                    $this.material_select('destroy');
                    $this.material_select();
                }
                var data = (typeof($this.data('data')) !== 'undefined') ? $this.data('data') : null;

                $.ajax({
                    method: "POST",
                    dataType: "json",
                    data: data,
                    url: url
                }).done(function (response) {
                    if (!response.ok) {
                        console.log('Select cascade: Error retrieving data from "' + url + '".');
                        return false;
                    }

                    if (response.list.length == 1) {
                        var row = response.list[0];
                        var $option = $("<option></option>").attr("value", row.id).text(row.name).attr('selected', true);
                        $this.append($option);
                    } else {
                        for (i in response.list) {
                            var row = response.list[i];
                            var $option = $("<option></option>").attr("value", row.id).text(row.name);
                            if (preloaded && row.id == preloaded) {
                                $('option', $this).removeAttr('selected');
                                $option.attr('selected', true);
                            }
                            $this.append($option);
                        }
                    }

                    preloaded = false;
                    $parent.removeAttr('disabled');
                    if ($parent.is('.mdb-select')) {
                        $parent.material_select('destroy');
                        $parent.material_select();
                    }

                    if (response.list.length) {
                        $this.removeAttr('disabled');
                    }
                    if ($this.is('.mdb-select')) {
                        $this.material_select('destroy');
                        $this.material_select();
                    }
                    $this.change();
                });
            }).change();
        });
    }
});

$(function () {
    $('.mdb-select').material_select();

    // Select en cascada ajax

    $('form.form-search').submit(function () {
        var q = $(this).find("input[name=q]").val();
        if (!q) return false;
        window.location = trans_url("search") + q;
        return false;
    });

    $('.select-cascade').cascade();

    $(".newsletter-form").click(function () {
        $(this).validate({
            ignore: [],
            focusInvalid: false,
            // invalidHandler: function (form, validator) {
            //     if (!validator.numberOfInvalids())
            //         return;
            //     var elem = validator.errorList[0].element.id;

            //     $(window).scrollTo("#" + elem, 200, {
            //         easing: 'easeOutQuart',
            //         offset: -240,
            //         queue: false,
            //         onAfter: function () {
            //             $("#" + elem).focus();
            //         }
            //     });
            // },
            onfocusout: false,
            rules: {
                email: {
                    required: true,
                    email: true
                },
                newsletter_terms: {
                    required: true,
                }
            },
            messages: {},
            showErrors: function (errorMap, errorList) {
                blnValidated_at_least_once = true;
                $.each(this.successList, function (index, value) {

                    var _popover = $(value).popover({
                        trigger: 'manual',
                        placement: 'top',
                        content: value.message,
                        container: '.form-group',
                        template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                    });

                    $(value).popover('hide');
                });
                var x_count = 0;
                $.each(errorList, function (index, value) {

                    if (x_count > 0) {

                    } else {
                        x_count++;

                        if (value.element.id.indexOf("cha_txt") > -1) {

                            var _popover = $(value.element).popover({
                                trigger: 'manual',
                                placement: 'bottom',
                                content: '',
                                container: 'body',
                                template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                            });

                            $(value.element).attr('data-content', value.message);

                            _popover.popover('show');
                            setTimeout(function () {
                                $(value.element).popover('hide');
                            }, 2000);

                        } else {

                            var _popover = $(value.element).popover({
                                trigger: 'manual',
                                placement: 'top',
                                content: '',
                                container: 'body',
                                template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                            });

                            $(value.element).attr('data-content', value.message);

                            _popover.popover('show');

                            setTimeout(function () {
                                $(value.element).popover('hide');
                            }, 2000);

                        }
                    }
                });
            },
            submitHandler: function (form) {
                if (!window.already_submitted) {
                    window.already_submitted = true;
                    form.method = "POST";
                    form.action = trans_url("newsletter/proc");
                    form.submit();
                }
            }
        });
    });

    $.localScroll({
        target: 'body', // could be a selector or a jQuery object too.
        queue:true,
        duration:1000,
        hash:true,
        onBefore:function( e, anchor, $target ){
            // The 'this' is the settings object, can be modified
        },
        onAfter:function( anchor, settings ){
            // The 'this' contains the scrolled element (#content)
        }
    });

    var $news_list = $('#news-list');
    if ($news_list.length) {
        var $news_list_wrapper = $('#news-list-more');
        var $news_list_next = $news_list_wrapper.find('a');

        if ($news_list.data('more') == '1') {
            var current_row = parseInt($news_list.data('row'));
            var in_query = false;
            $news_list_next.click(function (e) {
                e.preventDefault();
                var $this = $('this');
                if (in_query) return;
                in_query = true;
                $.ajax({
                    'url': trans_url("news") + 'more/' + (current_row + 1),
                    'dataType': 'json'
                }).done(function (data) {
                    if (data.ok) {
                        current_row++;
                        var $row = $('<div />',{html: data.html});
                        $row.find('.card').hide();
                        $news_list.append($row.html());
                        $news_list.find('.card').not(':visible').fadeIn();
                        if (!data.more) {
                            $news_list_next.unbind('click');
                            $news_list_wrapper.fadeOut();
                        }
                    }
                    in_query = false;
                });
            });
            $news_list_wrapper.show();
        }
    }
});

function popup(url, title, w, h, options) {
    if (typeof(options) === 'undefined') options = 'toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no';

    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;
    var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left + options);
    if (window.focus) {
        newWindow.focus();
    }
}

// Shares
$(function () {

    var title = trans('elite_professional');
    var w = 640;
    var h = 320;

    $('.share-module .btn-share-fb').click(function () {
        var url = 'https://www.facebook.com/sharer.php?u=' + encodeURI(window.location.href);
        popup(url, title, w, h);
        return false;
    });
    $('.share-module .btn-share-li').click(function () {
        var url = 'https://www.linkedin.com/cws/share?url=' + encodeURI(window.location.href);
        popup(url, title, w, h);
        return false;
    });
    $('.share-module .btn-share-tw').click(function () {
        var url = 'https://twitter.com/intent/tweet?url=' + encodeURI(window.location.href);
        popup(url, title, w, h);
        return false;
    });
    $('.share-module .btn-share-gplus').click(function () {
        var url = 'https://plus.google.com/share?url=' + encodeURI(window.location.href);
        popup(url, title, w, h);
        return false;
    });
    $('.share-module .btn-share-pin').click(function () {
        var pic = $('meta[property="og:image"]').attr('content');
        var share = '?url=' + encodeURI(window.location.href) + '&description=' + encodeURIComponent(document.title)
        if (pic) { share += '&media=' + pic; }
        var url = 'http://pinterest.com/pin/create/button/' + share;
        popup(url, title, w, h);
        return false;
    });
});

